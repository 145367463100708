import {getLocalizedStrings} from '@/localization/localization-store';

/**
 * A hook for navigating to a path.
 * This hook is used to navigate to a path in the store.
 * Because it uses Preact signals under the hood, it is safe to use outside of components.
 *
 * @returns A function that navigates to the specified path.
 * @example
 * ```tsx
 * const navigate = useNavigate();
 * navigate('/cart');
 * ```
 */
export function useNavigate() {
  const rootRoute = getLocalizedStrings().storeRootRoute;
  return (path: string) =>
    (window.location.href = rootRoute + path.replace(/^\//, '')); // Remove leading slash, if present
}
